@charset "utf-8";

@import '../mixin';
@import '../variable';

@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');

.headSection {
    margin-bottom: 26px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media screen and (min-width: $xxl) {
        margin-bottom: 32px;
    }
    @media screen and (max-width: $sm) {
        margin-bottom: 15px;
    }
    h2 {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 34px;
        font-weight: bold;
        color: $color-brand;
        line-height: 1.3;
        @media screen and (min-width: $xxl) {
            font-size: 40px;
        }
        @media screen and (max-width: $lg) {
            font-size: 30px;
        }
        @media screen and (max-width: $sm) {
            font-size: 28px;
        }
        span {
            margin-left: 8px;
            font-size: 14px;
            font-weight: 500;
            color: $color-base;
            position: relative;
            top: 5px;
            @media screen and (min-width: $xxl) {
                font-size: 18px;
            }
            @media screen and (max-width: $lg) {
                font-size: 12px;
            }
            @media screen and (max-width: $sm) {
                font-size: 10px;
                top: 3px;
            }
        }
    }
    a {
        font-size: 17px;
        font-weight: bold;
        color: $color-brand;
        @media screen and (min-width: $xxl) {
            font-size: 20px;
        }
        @media screen and (max-width: $lg) {
            font-size: 16px;
        }
        @media screen and (max-width: $sm) {
            font-size: 15px;
        }
    }
}

.firstSection {
    background-color: $color-border;
    padding: 47px 5.45% 53px 3.29%;
    @media screen and (max-width: $lg) {
        padding: 40px 5%;
    }
    @media screen and (max-width: $sm) {
        padding: 30px 5% 40px;
    }
    .inner {
        ul {
            display: flex;
            justify-content: space-between;
            @media screen and (max-width: $sm) {
                display: block;
            }
            li {
                width: 47.5%;
                background-color: #FFF;
                position: relative;
                top: 0;
                transition: $animate;
                @media screen and (max-width: $sm) {
                    width: 100%;
                    margin-bottom: 5%;
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
                a {
                    padding: 16px;
                    display: flex;
                    justify-content: flex-start;
                    @media screen and (min-width: $xxl) {
                        padding: 24px;
                    }
                    @media screen and (max-width: $sm) {
                        padding: 10px;
                    }
                    .img {
                        width: 100px;
                        height: 100px;
                        margin-right: 16px;
                        overflow: hidden;
                        @media screen and (min-width: $xxl) {
                            width: 150px;
                            height: 150px;
                            margin-right: 20px;
                        }
                        div {
                            width: 100%;
                            height: 100%;
                            transition: $animate;
                            transform: scale(1);
                        }
                    }
                    .blog-text {
                        flex: 1;
                        display: flex;
                        flex-direction: column-reverse;
                        justify-content: flex-end;
                        padding-top: 6px;
                        color: $color-brand;
                        @media screen and (max-width: $lg) {
                            padding-top: 0;
                        }
                        p {
                            display: none;
                        }
                        span {
                            font-size: 18px;
                            display: block;
                            margin-bottom: 16px;
                            line-height: 1.2;
                            @media screen and (min-width: $xxl) {
                                font-size: 22px;
                            }
                            @media screen and (max-width: $lg) {
                                font-size: 14px;
                                margin-bottom: 10px;
                            }
                            @media screen and (max-width: $sm) {
                                font-size: 12px;
                                margin-bottom: 5px;
                            }
                        }
                        h3 {
                            font-weight: bold;
                            @media screen and (min-width: $xxl) {
                                font-size: 20px;
                            }
                            @media screen and (max-width: $sm) {
                                font-size: 14px;
                            }
                        }
                    }
                }
                &:hover {
                    top: 5px;
                    a {
                        .img {
                            div {
                                transform: scale(1.05);
                            }
                        }
                    }
                }
            }
        }
    }
}

.secondSection {
    padding: 47px 5.45% 47px 3.29%;
    @media screen and (max-width: $lg) {
        padding: 40px 5%;
    }
    @media screen and (max-width: $sm) {
        padding: 30px 5% 15px;
    }
    .inner {
        ul {
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;
            @media screen and (max-width: $sm) {
                justify-content: space-between;
            }
            li {
                width: 31.5%;
                margin-right: 2.75%;
                margin-bottom: 40px;
                @media screen and (max-width: $lg) {
                    width: 31.6%;
                    margin-right: 2.5%;
                    margin-bottom: 30px;
                }
                @media screen and (max-width: $sm) {
                    width: 47.5%;
                    margin-right: 0;
                    margin-bottom: 7.5%;
                }
                &:nth-child(3),
                &:nth-child(6),
                &:nth-child(9) {
                    margin-right: 0;
                }
                a {
                    display: block;
                    position: relative;
                    top: 0;
                    transition: $animate;
                    .img {
                        width: 100%;
                        height: 16.7vw;
                        margin-bottom: 24px;
                        overflow: hidden;
                        @media screen and (max-width: $lg) {
                            height: 17.5vw;
                            margin-bottom: 15px;
                        }
                        @media screen and (max-width: $sm) {
                            height: 32vw;
                            margin-bottom: 10px;
                        }
                        div {
                            width: 100%;
                            height: 100%;
                            transition: $animate;
                            transform: scale(1);
                        }
                    }
                    h2 {
                        font-weight: bold;
                        color: $color-brand;
                        @media screen and (max-width: $lg) {
                            font-size: 15px;
                        }
                        @media screen and (max-width: $sm) {
                            font-size: 14px;
                        }
                    }
                    &:hover {
                        top: 5px;
                        .img {
                            div {
                                transform: scale(1.05);
                            }
                        }
                    }
                }
            }
        }
    }
}

.thirdSection {
    margin-bottom: 24px;
    @media screen and (max-width: $lg) {
        margin-bottom: 15px;
    }
    .map {
        width: 100%;
        height: 26.5vw;
        overflow: hidden;
        @media screen and (min-width: $xxl) {
            height: 32.5vw;
        }
        @media screen and (max-width: $lg) {
            height: 35vw;
        }
        @media screen and (max-width: $sm) {
            height: 50vw;
        }
        iframe {
            width: 100%;
            height: 100%;
        }
    }
}

.fourSection {
    .inner {
        position: relative;
        .bg {
            img {
                width: 100%;
            }
            @media screen and (max-width: $sm) {
                display: none;
            }
            &.smartphone {
                display: none;
                @media screen and (max-width: $sm) {
                    display: block;
                }
            }
            iframe {
                width: 100%;
                height: 38vw;
                @media screen and (min-width: $xxl) {
                    height: 40.5vw;
                }
                @media screen and (max-width: $lg) {
                    height: 37.5vw;
                }
                @media screen and (max-width: $md) {
                    height: 49.5vw;
                }
                @media screen and (max-width: $sm) {
                    height: 100vw;
                }
            }
        }
        a {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 299px;
            height: 73px;
            border-radius: 10px;
            background-color: #FFF9EB;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: 'Lato', sans-serif;
            font-size: 13px;
            font-weight: bold;
            color: $color-brand;
            transition: $animate;
            &:hover {
                top: calc(50% + 5px);
            }
            @media screen and (min-width: $xxl) {
                width: 375px;
                height: 80px;
                border-radius: 15px;
                font-size: 18px;
            }
            @media screen and (max-width: $sm) {
                width: 250px;
                height: 60px;
                font-size: 12px;
            }
            i {
                font-size: 28px;
                margin: 0 8px;
                margin-left: 10px;
                @media screen and (min-width: $xxl) {
                    font-size: 32px;
                }
                @media screen and (max-width: $sm) {
                    font-size: 24px;
                }
            }
        }
    }
}
